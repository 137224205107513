import React from 'react';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import Lorem from '../components/lorem';

const Blog = () => (
  <Layout>
    <Hero title="Nous Derniers Articles"/>
    <Lorem/>
  </Layout>
);

export default Blog;
